<template>
  <div>
    <!--    Start marquee -->
    <marquee-text
      :paused="paused"
      @mouseenter="paused = true"
      @mouseleave="paused = false"
      :duration="50"
    >
      <div class="d-flex">
        <artist
          v-for="artist in this.artists"
          :key="artist.id"
          :artist="artist"
          image-width="302px"
          container-height="381px !important"
        ></artist>
      </div>
    </marquee-text>
    <!--    End Marquee   -->
  </div>
</template>

<script>
import artist from "../../shared/components/artist";

export default {
  name: "lineup-marquee",
  data() {
    return {
      paused: false,
    };
  },
  props: ["artists"],
  components: { artist },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
