<template>
  <a
    :href="'/artists/' + artist.id"
    class="link text-uppercase text-decoration-none mr-2"
    :style="{ height: containerHeight }"
  >
    <div class="artist m-2 mt-0 h-100">
      <img
        :src="artist.attachments[0].attachment_url"
        class=""
        alt=""
        :style="{ width: imageWidth }"
        ref="artistImg"
        @load="setBioLineCount"
      />
      <div
        class="
          layer
          text-white
          font-weight-bold
          d-flex
          justify-content-end
          flex-column
          p-3
        "
      >
        <h5 class="artist-name text-uppercase mb-0">
          {{ artist.data.first_name }}
        </h5>
        <h5 class="artist-name text-uppercase">
          {{ artist.data.last_name }}
        </h5>
      </div>
      <div class="layer-2 d-flex flex-column justify-content-between">
        <div class="hover-artist-info">
          <p class="about-artist" :style="{ webkitLineClamp: bioLineCount }">
            {{ artist.data.bio }}
          </p>
          <a
            :href="'/artists/' + artist.id"
            class="link text-uppercase text-decoration-none"
            >view more</a
          >
        </div>
        <div>
          <h5 class="artist-name text-white text-uppercase mb-0">
            {{ artist.data.first_name }}
          </h5>
          <h5 class="artist-name text-white text-uppercase">
            {{ artist.data.last_name }}
          </h5>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: ["artist", "imageWidth", "containerHeight"],
  data: function () {
    return {
      bioLineCount: 5,
    };
  },
  methods: {
    setBioLineCount() {
      this.bioLineCount = parseInt(
        (this.$refs.artistImg.offsetHeight * 0.8) / 30
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../stylesheets/variables";

.artist {
  overflow: hidden;
  position: relative;
  border-radius: 4px;
}

.layer-2 {
  position: absolute;
  top: 100%;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #af2038;
  opacity: 0;
  transition: 0.3s;
  transition-delay: 0.1s;
  padding: 0 1rem 1rem;
}

.layer-2 .about-artist {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-line;
  font-size: $font-size-14;
  color: white;
  text-transform: none;
}

.layer-2 .link {
  border-bottom: 2px solid white;
  font-size: 1.2em;
  font-weight: 600;
  color: white;
}

.layer-2 .hover-artist-info {
  max-height: 90%;
}

.artist img {
  border-radius: 4px;
  object-fit: cover;
  min-height: 100%;
}

.artist .layer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: linear-gradient(219.21deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  transition: 0.2s;
}

.artist:hover .layer-2 {
  opacity: 1;
  top: 0;
}

.artist:hover .layer .artist-name {
  opacity: 0;
  transform: translateY(100%);
}

.artist .layer .artist-name,
.artist .layer-2 .artist-name {
  font-size: $font-size-32;
  font-weight: 600;
  transition: 0.1s;
  transform: translateY(0);
  opacity: 1;
}

.artist > p {
  color: red;
}

@media screen and (max-width: 992px) {
  .artist .layer .artist-name,
  .artist .layer-2 .artist-name {
    font-size: $font-size-18;
  }
}
</style>
