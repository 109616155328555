<template>
  <div
    class="music-player d-none d-lg-flex justify-content-between align-items-center position-fixed"
  >
    <iframe
      ref="scWidget"
      class="d-none"
      width="100%"
      height="450"
      scrolling="no"
      frameborder="no"
      src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/36669642"
    ></iframe>

    <h5
      class="player_track-title text-nowrap overflow-hidden m-0"
      title="Title"
    >
      <span class="d-inline-block">{{ trackTitle }}</span>
    </h5>
    <div
      class="player_controls"
      :class="playerInitialized ? 'enable-controls' : 'disable-controls'"
    >
      <span
        class="controls_prev-track cursor-pointer"
        @click="widget.prev()"
        title="Previous"
      >
        <i class="fas fa-backward fa-fw"> </i>
      </span>
      <span
        class="controls_play-pause cursor-pointer"
        @click="isPlaying ? onPause() : onPlay()"
        :title="isPlaying ? 'Pause' : 'Play'"
      >
        <i class="fas fa-fw" :class="isPlaying ? 'fa-pause' : 'fa-play'"> </i>
      </span>
      <span
        class="controls_next-track cursor-pointer"
        @click="widget.next()"
        title="Next"
      >
        <i class="fas fa-forward fa-fw"> </i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "player",
  components: {},
  props: {},
  data: function () {
    return {
      trackTitle: "Loading...",
      soundsList: [],
      widget: null,
      isPlaying: false,
      playerInitialized: false,
    };
  },
  methods: {
    onPlay() {
      this.widget.play();
      this.isPlaying = true;
    },
    onPause() {
      this.widget.pause();
      this.isPlaying = false;
    },
  },
  mounted() {
    let widgetIframe = this.$refs.scWidget,
      widget = SC.Widget(widgetIframe);

    this.widget = SC.Widget(widgetIframe);

    widget.bind(SC.Widget.Events.READY, () => {
      this.playerInitialized = true;
      if (window.innerWidth >= 992) {
        setTimeout(() => {
          this.onPlay();
        }, 500);
      }

      widget.getSounds((sounds) => {
        this.soundsList = sounds;
      });

      widget.bind(SC.Widget.Events.PLAY, () => {
        // get information about currently playing sound
        widget.getCurrentSoundIndex((index) => {
          if (this.soundsList[index] !== null) {
            this.trackTitle = this.soundsList[index].title;
          }
        });
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../../stylesheets/_variables.scss";

$radius-2px: 2px;

.music-player {
  background-color: $color-flat-yellow;
  width: 330px;
  padding: 0.5rem 0.8rem;
  top: 50vh;
  right: 0;
  z-index: 9999;
  transform: rotate(-90deg) translateY(calc(330px / 2.3)); // width of the player (330) divided by half (2.3)
}

.player_track-title {
  font: {
    size: $font-size-12;
    weight: 600;
  }
  letter-spacing: 0.2px;
  color: $color-cobalt;
  background-color: $color-flat-yellow-dark;
  width: 200px;
  padding: 0.3rem 0 0.3rem 0.5rem;
}

.music-player,
.player_track-title {
  border-radius: $radius-2px;
}

.player_track-title,
.player_controls span .fas {
  color: $color-cobalt;
}

.controls_play-pause {
  margin: 0 0.3rem;
}

.enable-controls > span {
  pointer-events: auto;
  opacity: 1;
}

.disable-controls > span {
  pointer-events: none;
  opacity: 0.5;
}
</style>