<template>
  <div>
    <div
      class="d-flex align-items-center justify-content-between header-with-link flex-wrap"
    >
      <h2
        class="header-with-link-title mb-0 sb-yellow title newsreader mr-3 mb-lg-0"
        :class="titleClasses"
      >
        {{ title }}
      </h2>
      <a
        :href="href"
        class="text-white text-uppercase text-decoration-none header-with-link-link"
        >{{ link }}</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "header-with-link",
  props: ["title", "link", "href", "titleClasses"],
  data: function () {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "../../stylesheets/variables";

.header-with-link {
  margin-bottom: 1.4375rem;
}

.header-with-link-title {
  font: {
    size: $font-size-54;
  }
}

.header-with-link-link {
  font: {
    size: $base-font-size;
    weight: 600;
  }
  border-bottom: 2px solid white;
}

@media only screen and (max-width: 992px) {
  .header-with-link {
    margin-bottom: $font-size-14;
  }

  .header-with-link-title {
    font: {
      size: $font-size-32;
    }
  }

  .header-with-link-link {
    font: {
      size: $font-size-12;
    }
    margin-top: $font-size-12;
  }
}
</style>
