<template>
  <div class="home-wrapper position-relative">
    <!-- <player></player> -->
    <section class="hero-slide position-relative">
      <picture>
        <source
          media="(max-width:768px)"
          :srcset="require('images/frontend/home/banner-left.svg')"
        />
        <img
          class="home-banner-left-shape position-absolute"
          :src="require('images/frontend/home/banner-left.svg')"
          alt="Banner left"
        />
      </picture>
      <picture>
        <source
          media="(max-width:768px)"
          :srcset="require('images/frontend/home/banner-right.svg')"
        />
        <img
          class="home-banner-right-shape position-absolute"
          :src="require('images/frontend/home/banner-right.svg')"
          alt="Banner right"
        />
      </picture>
      <div class="sb-container">
        <div class="video-container">
          <video loop autoplay id="vid" muted ref="vid">
            <source
              src="https://sandbox-festival.s3.eu-central-1.amazonaws.com/sandboxafter2019.mp4"
              type="video/mp4"
            />
          </video>
          <div class="overlay d-flex align-content-center">
            <div class="row flex-grow-1 align-items-lg-center">
              <div class="col-xl-8 col-sm-10 content">
                <h2 class="header-title text-white mb-4">
                  {{ homeData.data.banner_title }}
                </h2>
                <p class="description mb-5">
                  {{ homeData.data.banner_text }}
                </p>
                <div class="d-lg-block d-none">
                  <a
                    :href="homeData.data.call_to_action_url"
                    class="
                      text-decoration-none text-uppercase text-white
                      artists-link
                    "
                    >{{ homeData.data.call_to_action_text }}</a
                  >
                </div>
                <div class="d-block d-lg-none">
                  <a
                    v-if="homeData.data.booking_visibility == '1'"
                    href=""
                    class="
                      buy-your-ticket
                      btn-block
                      text-center text-decoration-none text-uppercase text-white
                    "
                    >buy your pass</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--    End header-->

    <!--   Start Artists -->
    <section class="artists" v-if="artists.length > 0">
      <div class="sb-container">
        <header-with-link
          :title="homeData.data.lineup_title"
          href="/2022"
          link="View full lineup"
        ></header-with-link>
        <div class="artists-names">
          <div class="d-flex align-items-center flex-wrap">
            <a
              :href="'artists/' + artist.id"
              v-for="(artist, index) in sortedArtists"
              :key="index"
              class="mr-2 mr-lg-3 text-decoration-none artist-name-home"
            >
              <h4 class="text-name d-inline text-uppercase text-white">
                {{ artist.data.name }}
              </h4>
              <h4 class="d-inline">
                <sup class="sb-yellow country text-uppercase">{{
                  artist.data.country || ""
                }}</sup>
              </h4>
              <h4 class="info text-uppercase d-inline">
                {{ artist.data.info || "" }}
              </h4>
            </a>
          </div>
        </div>
      </div>
      <div class="artist-list">
        <lineup-marquee :artists="artists"></lineup-marquee>
      </div>
    </section>
    <!--    End Artists-->

    <!--    Start What people say about us-->
    <section
      class="about-us"
      v-if="articles.length > 0 && homeData.data.news_visibility == '1'"
    >
      <div class="sb-container">
        <div class="row">
          <div class="col-lg-5 mb-5">
            <header-with-link
              :title="homeData.data.news_title"
              link="view all news"
              href="/news"
              title-classes="mb-lg-4 mb-3"
            ></header-with-link>
          </div>
          <div class="col-lg-7">
            <a
              class="box row text-decoration-none mb-5"
              :href="'/news/' + article.id"
              v-for="(article, index) in articles"
              :key="index"
            >
              <div class="col-lg-6">
                <img
                  :src="
                    require('images/frontend/news/Group' +
                      randomNumber() +
                      '.jpg')
                  "
                  alt="News card"
                  class="img-fluid w-100"
                />
              </div>
              <div class="col-lg-6">
                <div class="description pt-3 pt-lg-3">
                  <h5 class="date text-uppercase mb-2">
                    {{ getDate(article.data.date) }}
                  </h5>
                  <h5 class="box-title text-white">
                    {{ article.data.title }}
                  </h5>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <!--    End What people say about us-->

    <!--    Start YouTube Widget-->
    <section class="sandbox-video position-relative">
      <picture>
        <source
          media="(max-width:768px)"
          :srcset="require('images/frontend/home/aftermovie-card-mob.svg')"
        />
        <img
          class="home-aftermovie-card-shape position-absolute h-100"
          :src="require('images/frontend/home/aftermovie-card.svg')"
          alt="Aftermovie shape"
        />
      </picture>
      <div class="sb-container mt-4 mt-lg-0">
        <div class="row">
          <div class="col-lg-6 mb-5 mb-lg-0 z-index-9">
            <youtube
              class="video w-100"
              :video-id="homeData.data.aftermovie_video"
              :fit-parent="true"
              ref="youtube"
            ></youtube>
          </div>
          <div class="col-lg-6 pl-xl-5 pr-xl-4 d-flex align-items-center">
            <div>
              <h2
                class="sandbox-description custom-title newsreader sb-dark-blue"
              >
                {{ homeData.data.aftermovie_text }}
              </h2>
              <a
                href="/experience"
                class="
                  text-white text-uppercase
                  link
                  text-decoration-none
                  position-relative
                  z-index-9
                "
                >sandbox experience</a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--    End YouTube Widget-->

    <!--    Start Festival over the years -->
    <section
      class="festival-over-years"
      v-if="media.length > 0 && homeData.data.media_visibility == '1'"
    >
      <div class="sb-container">
        <header-with-link
          :title="homeData.data.media_title"
          href="/media"
          link="all media"
        ></header-with-link>

        <div class="image-list">
          <div class="row">
            <a
              href="/media"
              class="col-lg-2 col-6 media-container cursor-pointer"
              v-for="(img, index) in media.slice(0, mediaLimit)"
              :key="index"
            >
              <!-- <div class="col-lg-2 col-6 media-container cursor-pointer"> -->
              <b-img
                center
                fluid
                :src="img.data.mobile_url"
                :alt="img.data.filename"
              ></b-img>
              <!-- </div> -->
            </a>
          </div>
        </div>
      </div>
    </section>
    <!--    End Festival over the years -->

    <picture>
      <source
        media="(max-width:768px)"
        :srcset="require('images/frontend/home/media-left-mob.svg')"
      />
      <img
        v-if="media.length > 0 && homeData.data.media_visibility == '1'"
        class="home-media-left-shape position-absolute"
        :src="require('images/frontend/home/media-left.svg')"
        alt="Media shape"
      />
    </picture>
  </div>
</template>

<script>
import artist from "../shared/components/artist.vue";
import player from "../shared/components/music-player.vue";
import headerWithLink from "../shared/components/header-with-link-component.vue";
import lineupMarquee from "../shared/components/lineup-marquee-component";
var moment = require("moment");

export default {
  name: "home",
  components: { artist, player, headerWithLink, lineupMarquee },
  props: ["artists", "media", "articles", "homeData"],
  data: function () {
    return {
      videoId: "G_DB1xAaOmE",
      mediaLimit: window.innerWidth >= 992 ? this.media.length : 6,
    };
  },
  computed: {
    sortedArtists() {
      return this.artists.sort(function (a, b) {
        return a.data.name.localeCompare(b.data.name);
      });
    },
  },
  methods: {
    years: function (yearsStr) {
      var parsedYears = JSON.parse(yearsStr);
      return parsedYears.constructor.name === "Number"
        ? [parsedYears]
        : parsedYears;
    },
    randomNumber: function () {
      return Math.floor(Math.random() * (5 - 1 + 1)) + 1;
    },
    getDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD MMMM YYYY");
    },
  },
};
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
@import "../stylesheets/variables";

.home-wrapper {
  // Start Header
  .hero-slide {
    img.home-banner-left-shape {
      top: 50%;
      transform: translateY(-50%);
    }

    img.home-banner-right-shape {
      bottom: -2rem;
      right: 1rem;
    }

    .video-container {
      width: 100%;
      height: 700px;
      position: relative;
      overflow: hidden;

      video {
        width: 100%;
        margin-bottom: 0;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: rgba(black, 0.4);
        height: 100%;
        width: 100%;

        .content {
          //padding: 6rem;
          padding: 0 4rem;

          .header-title {
            font-size: 71px;
            line-height: 76px;
            font-weight: 600;

            .newsreader {
              font-size: 6rem;
            }
          }

          .description {
            color: white;
            font-size: 1.2em;
            line-height: 1.73em;
            font-weight: 500;
            white-space: pre-line;
          }

          .artists-link {
            border-bottom: 2px solid white;
            font-size: 1.2em;
            font-weight: 600;
          }

          .buy-your-ticket {
            font-weight: 600;
            padding: 1rem 2rem;
          }
        }
      }
    }
  }

  //  End Header

  //  Start Artists
  .artists {
    padding: 5rem 0;

    .header {
      .title {
        font-size: 3em;
      }

      .view-all {
        border-bottom: 2px solid white;
        font-size: 1.2em;
        font-weight: 600;
      }
    }

    .artists-names {
      margin-bottom: 3.3125rem;
      .text-name {
        font: {
          size: 3rem;
          weight: 700;
        }
        line-height: 61px;
        &:hover {
          text-decoration: underline;
        }
      }

      .country {
        font: {
          weight: 700;
          size: 1.75rem;
        }
      }
      .info {
        color: white;
        font: {
          size: 3rem;
          weight: 100;
        }
      }
    }
  }

  //  End Artists

  //  Start What People Say About Us
  .about-us {
    padding: 4rem 0;

    .about-us-title {
      font-size: 4em;
    }

    .view-all {
      border-bottom: 2px solid white;
      font-size: 1.2em;
      font-weight: 600;
    }

    .description {
      .date {
        font-weight: 600;
        color: rgba(white, 0.4);
        font-size: $font-size-14;
      }

      .box-title {
        font-size: $font-size-26;
        font-weight: 600;
        line-height: 36px;
      }

      .box-link {
        font-size: 1.4em;

        &:hover {
          color: rgba($color-dark-pink, 0.8);
        }
      }
    }
  }

  //  End What People Say About Us

  //  Start Sandbox video
  .sandbox-video {
    padding: 7rem 0;
    background-color: $color-light-cyan;

    .video {
      max-width: 100%;
    }

    .sandbox-description {
      margin-bottom: 46px;
      white-space: pre-line;
    }

    .link {
      border-bottom: 2px solid white;
      font-size: 1.2em;
      font-weight: 600;
    }

    .home-aftermovie-card-shape {
      top: 0;
      right: 0;
    }
  }

  //  End Sandbox video

  //  Start festival-over-years
  .festival-over-years {
    padding: 7rem 0;
    position: relative;

    .sb-container {
      z-index: 4;
      position: relative;
    }

    .header {
      .title {
        font-size: 3em;
        z-index: 1;
      }

      .view-all {
        border-bottom: 2px solid white;
        font-size: 1.2em;
        font-weight: 600;
      }
    }
  }

  .media-container {
    margin-bottom: 1.43em;
  }

  .media-container::after {
    content: ""; // ::before and ::after both require content
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    right: 15px;
    height: 100%;
    max-width: 100%;
    transition: 0.5s ease;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
  }

  .media-container:hover::after {
    opacity: 1;
  }

  .media-container img {
    aspect-ratio: 1/1;
    width: 100%;
    object-fit: cover;
  }
  //  End festival-over-years

  .home-media-left-shape {
    bottom: 100px;
  }
}

@media screen and (max-width: 992px) {
  .home-wrapper {
    .hero-slide {
      img.home-banner-right-shape {
        right: 0rem;
      }

      .video-container {
        width: 100%;
        height: 700px;

        video {
          //width: 100%;
          width: auto;
          height: 100%;
          margin-bottom: 0;
          object-fit: fill;
          object-position: center;
        }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          background-color: rgba(black, 0.4);
          height: 100%;
          width: 100%;

          .content {
            padding: 2rem;

            .header-title {
              font-size: 46px;
              line-height: $font-size-48;
              font-weight: 600;

              .newsreader {
                font-size: 3rem;
              }
            }

            .description {
              color: white;
              font-size: $font-size-14;
              line-height: 1.63em;
              font-weight: 300;
            }

            .artists-link {
              border-bottom: 2px solid white;
              font-size: 1.2em;
              font-weight: 600;
            }

            .buy-your-ticket {
              background-color: $color-dark-red;
            }
          }
        }
      }
    }

    .about-us {
      padding: 2rem 0;

      .about-us-title {
        font-size: 2.5em;
      }

      .description {
        .box-title {
          font-size: $font-size-21;
          line-height: 27px;
        }
      }
    }

    .artists {
      padding: 2rem 0;
      .header {
        .title {
          font-size: 2.5em;
        }
      }

      .artists-names {
        margin-bottom: 23px;
        .text-name {
          font: {
            size: $font-size-26;
          }
          line-height: $font-size-35;
        }

        .country {
          font: {
            size: $base-font-size;
          }
        }
        .info {
          font: {
            size: $font-size-26;
          }
        }
      }
    }

    .sandbox-video {
      padding: 4.4375rem 0;

      .home-aftermovie-card-shape {
        width: 100%;
      }

      .sandbox-description {
        margin-bottom: $font-size-14;
      }

      .link {
        font-size: $font-size-12;
      }
    }

    .festival-over-years {
      padding-top: 4rem;
      padding-bottom: 0rem;
      .header {
        .title {
          font-size: 2.5em;
        }
      }
    }

    .image-list {
      margin-top: $font-size-32;
    }

    .media-container {
      margin-bottom: 0.8125rem;
    }

    .media-container:nth-child(odd) {
      padding-right: 12.5px;

      &:after {
        right: 12.5px;
      }
    }

    .media-container:nth-child(even) {
      padding-left: 12.5px;

      &:after {
        left: 12.5px;
      }
    }

    .home-media-left-shape {
      bottom: 0px;
    }
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee-reverse {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>
