import './home-style.scss'
import Vue from "vue";
import home from "./home-component.vue";
import "../../backend/components/libraries/vue-bootstrap"
// youtube script
import "../libraries/youtube";
// vue marquee
import "../libraries/vue-maruqee"

document.addEventListener("turbolinks:load", () => {
  var element = document.getElementById("home");

  if (element != null) {
    const props = JSON.parse(element.getAttribute("props"));

    new Vue({
      render: (h) =>
        h(home, {
          props,
        }),
    }).$mount(element);
  }
})
